<template>
  <div class="">
    <div class="row page-title">
      <label>PRODUCTION EXPENSE AMEND REQUEST</label>
    </div>
    <div class="row stepper-outer">
      <div class="col-lg-10 mx-auto chevron-steps-container">
        <chevron-steps :steps="filteredTabs" @click="navigate"></chevron-steps>
      </div>
    </div>
    <div class="pt-30 row">
      <div class="col-lg-12">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
const EmployeeInfo = () => import("../components/Vendor/ProdEXPAmends/EmployeeInfo");
const SetupInfo = () => import("../components/Vendor/ProdEXPAmends/SetupInfo");
const BankingInfo = () => import("../components/Vendor/ProdEXPAmends/BankingInfo");
const EmployeeRelationship = () => import("../components/Vendor/ProdEXPAmends/EmployeeRelationship");
const Review = () => import("../components/Vendor/ProdEXPAmends/Review");
import { mapGetters } from "vuex";

export default {
  mounted() {
    let id = this.id;
    if (id) {

      if (this.isDraft == true) {
        this.$store.dispatch("prodexpamend/getDraftData", parseInt(id));
      } else {
        this.$store.dispatch("prodexpamend/GetFormData", parseInt(id))
          .catch((err) => {
            if (err.response && err.response.status == 403) {
              this.$router.push({
                name: "error",
                params: {
                  message: "UnAuthorized"
                },
              });
            }
            else {
              this.Error = true;
              alert(err);
            }
          });
      }
    }
    this.$emit("can-continue", {
      value: true
    });
    this.$on("stepChanged", (tabIndex) => {
      this.UpdateSteps(tabIndex);
    });
    this.$on("stepInitiated", (tabIndex) => {
      this.InProgressStep(tabIndex);
    });
    // if (window.performance.navigation.type === 1) {
    //   this.$router.push({ name: 'Production Expense Amend Employee Info' });
    // }
  },
  methods: {
    UpdateSteps: function (index) {
      for (var i = 0; i < this.tabs.length; i++) {
        if (i <= index || index == this.tabs.length - 1) {
          this.$set(this.tabs[i], "state", "complete");
          this.completedStep = i;
        }
      }
    },
    InProgressStep: function (index) {
      for (var i = 0; i < this.tabs.length; i++) {
        if (i == index) {
          this.$set(this.tabs[i], "state", "in progress");
        } else if (i <= this.completedStep) {
          this.$set(this.tabs[i], "state", "complete");
        } else if (this.tabs[i].state != "complete") {
          this.$set(this.tabs[i], "state", "");
        }
      }
      if (this.completedStep < 0 && index > 0) {
        if (this.id) {
          this.$router.push({
            path: `${this.tabs[0].route}/${parseInt(this.id)}`,
          });
        } else {
          this.$router.push({
            path: this.tabs[0].route,
          });
        }
      }
    },
    enableNext: function () {
      this.$emit("can-continue", {
        value: true
      });
    },
    navigate(index) {
      window.console.log("navigate");
      let currentRouteIndex = this.$route.meta.tabIndex;
      if (index > this.completedStep + 1 && index > currentRouteIndex) {
        //this.$store.dispatch("progress/setErrorMessage","Please complete previous steps to proceed!");
        // this.$root.toastDanger("check color");
      } else {
        if (this.id) {
          this.$router.push(`${this.tabs[index].route}/${this.id}`);
        } else {
          this.$router.push(this.tabs[index].route);
        }
      }
    },
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    isDraft: {
      type: Boolean,
      required: false,
    },
  },
  created() {
    // localStorage.setItem('clienticId',null);
    this.$store.commit("prodexpamend/reset");
    // if (this.id && this.isDraft != true) {
    //   this.completedStep = 4;
    // }
  },
  // activated() {
  //     if (!this.CanCreateRequest) {
  //         this.$router.push({
  //             name: "error",
  //             params: {
  //                 message: "UnAuthorized"
  //             },
  //         });
  //     }
  // },
  data() {
    return {
      completedStep: -1,
      UpdateTabValue: null,
      tabs: [
        {
          icon: "1",
          name: "1",
          description: "Employee/Request Type Info",
          component: EmployeeInfo,
          route: "/Request/Vendor/ProdEXPAmends/EmployeeInfo",
          state: "in progress",
        },
        {
          icon: "2",
          name: "2",
          description: "SETUP INFO",
          route: "/Request/Vendor/ProdEXPAmends/SetupInfo",
          component: SetupInfo,
          state: this.id && this.isDraft != true ? "complete" : "",
        },
        {
          icon: "3",
          name: "3",
          description: "BANKING INFO",
          route: "/Request/Vendor/ProdEXPAmends/BankingInfo",
          component: BankingInfo,
          state: this.id && this.isDraft != true ? "complete" : "",
        },
        {
          icon: "4",
          name: "4",
          description: "EMPLOYEE RELATIONSHIP",
          route: "/Request/Vendor/ProdEXPAmends/EmployeeRelationship",
          component: EmployeeRelationship,
          state: this.id && this.isDraft != true ? "complete" : "",
        },
        {
          icon: "5",
          name: "5",
          description: "REVIEW",
          route: "/Request/Vendor/ProdEXPAmends/Review",
          component: Review,
          state: "",
        }
      ],
    };
  },
  watch: {
    "ProdEXPAmend.ChangeType": function (newValue, oldValue) {
      this.UpdateTabValue = newValue;
      if (oldValue == "Address"){
        this.tabs.filter((tab) => tab.name == "3").forEach((tab) => tab.state = "");
      }
      else if (oldValue == "Banking"){
        this.tabs.filter((tab) => tab.name == "2").forEach((tab) => tab.state = "");
      }
    },
    "BillingInfo.BillingParty": function () {
      let timeOnlyOptions = [
        "Time Only (Client Related Time MED)",
        "Time Only (Internal Project Related Time ADM)",
      ];
      let timeOnlySelected = timeOnlyOptions.includes(
        this.BillingInfo.BillingParty
      );
      this.$emit("billingPartyChanged", timeOnlySelected);
    },
  },
  computed: {
  filteredTabs() {
    // Filter the tabs array based on the value of this.NewChangeValue
    if (this.UpdateTabValue === "Address") {
      return this.tabs.filter((tab) => tab.name !== "3");
    }
    return this.tabs;
  },
    ...mapGetters("client", {
      BillingInfo: "ClientBillingInformations",
    }),
    ...mapGetters("prodexpamend", {
      ProdEXPAmend: "ProdEXPAmend",
    }),
    // CanCreateClientRequest() {
    //   return this.$store.getters.canCreateClient;
    // },
    // CanAccessList() {
    //   return this.$store.getters.canAccessList;
    // },
  },
};
</script>

<style scoped>
.stepper-box,
.stepper-box .top .steps-wrapper .step .circle {
  background-color: transparent !important;
}

.content-holder {
  background-color: #ffffff !important;
}

.row.page-title {
  height: 55px;
  background-color: #eef3f5 !important;
}

.row.page-title>label {
  padding: 14px 0px 14px 55px !important;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.81px;
  color: #4d5161;
  text-transform: none !important;
}


.progress-bar[data-v-ef603d5c]:not(.hide-number):before {
  width: 30px !important;
  height: 30px !important;
  padding-top: 6px !important;
}
</style>
